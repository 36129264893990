import React, {useContext, useEffect, useState} from "react";
import {Theme} from "@mui/material";
import {createStyles, makeStyles, useTheme} from "@mui/styles";
import {AuthServiceContext} from "../../../provider/AuthServiceProvider";
import MembersFilter, {MemberFilter} from "./MembersFilter";
import {ContactServiceApi, GroupServiceApi} from "../../../api/MemberServiceApi";
import {IContact} from "../../../model/member/IContact";
import useMediaQuery from "@mui/material/useMediaQuery";
import {groupToText, UserGroup} from "../../../model/IUser";
import {IMemberIdentifier} from "../../../model/member/IMember";
import {ClubMember} from "../../../model/member/ClubMember";
import ContentContainer from "../../../components/ContentContainer";
import {BoatServiceApi} from "../../../api/BoatServiceApi";
import IBoat, { IBoatswain } from "../../../model/boat/IBoat";

const useStyles = makeStyles((theme: Theme) => createStyles({
    membersContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "1280px",
        margin: "30px auto auto",
        [theme.breakpoints.down(500)]: {
            marginTop: "0"
        }
    },
    membersHeadline: {
        width: "100%",
        fontSize: "38px",
        fontWeight: 700,
        lineHeight: "52px",
        textAlign: "center",
        color: "#023553",
        margin: "40px auto",
        [theme.breakpoints.down(950)]: {
            fontSize: "28px",
            lineHeight: "40px"
        },
        [theme.breakpoints.down(500)]: {
            fontSize: "24px",
            lineHeight: "30px"
        }
    },
    membersViewContainer: {
        backgroundColor: "#ffffff",
        color: "#023553",
        width: "calc(950px - 40px)",
        margin: "0 auto 20px",
        padding: "20px",
        [theme.breakpoints.down(950)]: {
            width: "calc(415px - 40px)"
        },
        [theme.breakpoints.down(415)]: {
            margin: "0 0 20px 0",
            width: "calc(100% - 40px)"
        }
    },
    membersViewRow: {
        display: "flex",
        flexDirection: "row",
        margin: "0 auto",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: "rgba(54, 62, 76, 0.2)",
        [theme.breakpoints.down(950)]: {
            flexDirection: "column",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "rgba(54, 62, 76, 0.2)",
            borderBottom: "unset",
            paddingTop: "10px"
        },
        [theme.breakpoints.down(415)]: {
            width: "100%",
            margin: "0"
        },
        "&.header": {
            color: "#02B8B7",
            backgroundColor: "#F6F7F9",
            fontWeight: 500,
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            borderTopColor: "rgba(54, 62, 76, 0.2)",
        },
        "&.last": {
            [theme.breakpoints.down(950)]: {
                borderBottomStyle: "solid",
                borderBottomWidth: "1px",
                borderBottomColor: "rgba(54, 62, 76, 0.2)"
            },
        }
    },
    membersViewCell: {
        padding: "10px",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "rgba(54, 62, 76, 0.2)",
        [theme.breakpoints.down(950)]: {
            border: "unset",
            paddingTop: "0"
        },
        "&.name": {
            borderLeftStyle: "solid",
            borderLeftWidth: "1px",
            borderLeftColor: "rgba(54, 62, 76, 0.2)",
            width: "202px",
            [theme.breakpoints.down(950)]: {
                width: "calc(100% - 20px)",
                border: "unset"
            },
        },
        "&.mail": {
            width: "266px",
            [theme.breakpoints.down(950)]: {
                width: "calc(100% - 20px)",
            },
        },
        "&.phone": {
            width: "171px",
            [theme.breakpoints.down(950)]: {
                width: "calc(100% - 20px)",
            },
        },
        "&.role": {
            width: "171px",
            [theme.breakpoints.down(950)]: {
                width: "calc(100% - 20px)",
            },
        },
        "&.boat": {
            width: "171px",
            [theme.breakpoints.down(950)]: {
                width: "calc(100% - 20px)",
            },
        },
        "&.header": {
            [theme.breakpoints.down(950)]: {
                color: "#02B8B7",
                fontSize: "10px",
                fontWeight: 500,
                paddingTop: "0",
                paddingBottom: "0",
                lineHeight: "12px"
            }
        }
    },
    noMembers: {
        textAlign: "center",
        color: "#023553",
    }
}));

const groups = (): UserGroup[] => {
    return [
        UserGroup.BOARD_MEMBER,
        UserGroup.BOATSWAIN,
        UserGroup.ORGANIZER,
        UserGroup.INSTRUCTOR,
        UserGroup.ADMINISTRATOR
    ];
}

interface BoatswainOfBoat {
    boatswain: IBoatswain,
    boat: IBoat,
}

export default function Members() {
    // loading
    const [loading, setLoading] = useState<boolean>(false);
    // model
    const [members, setMembers] = useState<ClubMember[]>([]);
    const [filter, setFilter] = useState<MemberFilter>();
    const [membersOfGroupMap, setMembersOfGroupMap]
        = useState<Map<UserGroup, IMemberIdentifier[]>>(new Map<UserGroup, IMemberIdentifier[]>());
    const [boatswainsOfBoats, setBoatswainsOfBoats] = useState<BoatswainOfBoat[]>([]);

    const {getToken} = useContext(AuthServiceContext);

    const classes = useStyles();
    const theme: Theme = useTheme();
    const matchesMobile: boolean = useMediaQuery(theme.breakpoints.down(950));

    useEffect(() => {
        setLoading(true);
        const fetchData = async (): Promise<void> => {
            // fetch all contacts
            const contacts: IContact[] = await ContactServiceApi.getContacts(getToken());
            const boats: IBoat[] = await BoatServiceApi.getBoats(getToken());
            // fetch all members and boats in role of group BOATSWAIN
            const boatswainsOfBoats: BoatswainOfBoat[] = [];
            boats.filter((boat): boolean => boat.boatswains.length > 0)
                .forEach((boat: IBoat): void => {
                    boat.boatswains.forEach((boatswain: IBoatswain): void => {
                        boatswainsOfBoats.push({boatswain: boatswain, boat: boat});
                    });
                });
            setBoatswainsOfBoats(boatswainsOfBoats);
            // fetch all members of all groups
            const membersOfGroupMap = new Map<UserGroup, IMemberIdentifier[]>();
            for (let group of groups()) {
                const membersOfGroup: IMemberIdentifier[] = await GroupServiceApi.getMembersOfGroup(group, getToken());
                membersOfGroupMap.set(group, membersOfGroup);
            }
            setMembersOfGroupMap(membersOfGroupMap);
            // map contacts and groups
            const clubMembers: ClubMember[] = [];
            for (let contact of contacts) {
                const clubMember = new ClubMember(contact);
                for (let group of groups()) {
                    const membersOfGroup: IMemberIdentifier[] | undefined = membersOfGroupMap.get(group);
                    if (membersOfGroup) {
                        const identifiersOfGroup =
                            membersOfGroup.map((memberIdentifier: IMemberIdentifier) => memberIdentifier.identifier);
                        if (identifiersOfGroup.includes(contact.identifier)) {
                            clubMember.addGroup(group);
                        }
                    }
                }
                clubMembers.push(clubMember);
            }
            setMembers(clubMembers);
        }
        fetchData()
            .catch((error) => {
                console.error("unexpected error: " + error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [getToken]);

    const handleUpdateFilter = (filter: MemberFilter) => {
        setFilter(filter);
    }

    const handleFilterMember = (member: ClubMember) => {
        const membersOfGroup: IMemberIdentifier[] | undefined
            = filter?.memberGroup ? membersOfGroupMap.get(filter.memberGroup) : undefined;
        return (filter?.searchQuery === undefined
                || (member.getName().toLowerCase()
                    .includes(filter.searchQuery.toLowerCase()))
                || (member.mailAddress.toLowerCase()
                    .includes(filter.searchQuery.toLowerCase())))
            && (filter?.memberGroup === undefined
                || membersOfGroup === undefined
                || membersOfGroup.map(memberIdentifier => memberIdentifier.identifier)
                    .includes(member.identifier))
    }

    const filteredMembers: ClubMember[] = members.filter(member => handleFilterMember(member));

    const handleGroupsContent = (member: ClubMember): string => {
        return member.groups.map(groupToText).join(", ");
    }

    const isBoatswainMembership = (member: ClubMember): boolean => {
        return boatswainsOfBoats.findIndex((mapping: BoatswainOfBoat): boolean =>
            mapping.boatswain.identifier === member.identifier) !== -1
    }

    const renderBoatswainMembership = (member: ClubMember): React.ReactNode => {
        const indexOfThisMember: number = boatswainsOfBoats.findIndex((mapping: BoatswainOfBoat): boolean =>
            mapping.boatswain.identifier === member.identifier);
        if (indexOfThisMember !== -1) {
            const mapping: BoatswainOfBoat | undefined = boatswainsOfBoats.at(indexOfThisMember);
            return <><div>{mapping?.boat.name}</div><div>{mapping?.boatswain.position}. Bootsmann</div></>
        }
        return <></>
    }

    return (
        <ContentContainer process={loading}>
            <div className={classes.membersContainer}>
                <div className={classes.membersHeadline}>Mitglieder und Ehrenamtliche</div>
                <MembersFilter groups={groups()}
                               onUpdateFilter={handleUpdateFilter}/>
                <div className={classes.membersViewContainer}>
                    {!matchesMobile && (
                        <div className={classes.membersViewRow + " header"}>
                            <div className={classes.membersViewCell + " header name"}>Name</div>
                            <div className={classes.membersViewCell + " header mail"}>E-Mail-Adresse</div>
                            <div className={classes.membersViewCell + " header phone"}>Telefon</div>
                            <div className={classes.membersViewCell + " header role"}>Rolle im Verein</div>
                            <div className={classes.membersViewCell + " header boat"}>Betreutes Boot</div>
                        </div>
                    )}
                    {filteredMembers.map((member: ClubMember, index: number) => (
                        <div key={"member-" + index}
                             className={`${classes.membersViewRow}${index === filteredMembers.length - 1 ? " last" : ""}`}>
                            {matchesMobile && (
                                <div className={classes.membersViewCell + " header name"}>Name</div>
                            )}
                            <div className={classes.membersViewCell + " name"}>{member.getName()}</div>
                            {matchesMobile && (
                                <div className={classes.membersViewCell + " header mail"}>E-Mail-Adresse</div>
                            )}
                            <div className={classes.membersViewCell + " mail"}>{member.mailAddress}</div>
                            {(matchesMobile && member.phoneNumber) && (<>
                                <div className={classes.membersViewCell + " header phone"}>Telefon</div>
                                <div className={classes.membersViewCell + " phone"}>{member.getPhone()}</div>
                            </>)}
                            {!matchesMobile && (
                                <div className={classes.membersViewCell + " phone"}>{member.getPhone()}</div>
                            )}
                            {(matchesMobile && member.groups.length > 0) && (<>
                                <div className={classes.membersViewCell + " header role"}>Rolle im Verein</div>
                                <div className={classes.membersViewCell + " role"}>{handleGroupsContent(member)}</div>
                            </>)}
                            {!matchesMobile && (
                                <div className={classes.membersViewCell + " role"}>{handleGroupsContent(member)}</div>
                            )}
                            {(matchesMobile && isBoatswainMembership(member)) && (<>
                                <div className={classes.membersViewCell + " header boat"}>Betreutes Boot</div>
                                <div className={classes.membersViewCell + " boat"}>{renderBoatswainMembership(member)}</div>
                            </>)}
                            {!matchesMobile && (
                                <div className={classes.membersViewCell + " boat"}>{renderBoatswainMembership(member)}</div>
                            )}
                        </div>
                    ))}
                    {filteredMembers.length === 0 && (
                        <div className={classes.noMembers}>
                            {`${loading
                                ? "Mitglieder werden geladen"
                                : "Zu dieser Suchanfrage sind keine Mitglieder vorhanden."}`}
                        </div>
                    )}
                </div>
            </div>
        </ContentContainer>
    );
}
